import { useEffect, useState } from "react";
import { auth, functions } from "../firebase";
import { LoadingScreen } from "../compnents/otherCOmps";
import { httpsCallable } from "firebase/functions";
import { showErrorNotification } from "../compnents/errorMessagesComp";

export default function CasinoApp() {
  const [link, setLink] = useState(null);

  useEffect(() => {
    getLink();
  }, []);

  useEffect(() => {
    function handleMessage(event) {
      if (event.data === "showBackButton") {
        window.Telegram.WebApp.BackButton.show();
      } else if (event.data === "hideBackButton") {
        window.Telegram.WebApp.BackButton.hide();
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.onClick(() => {
      const iframe = document.getElementById("casino-iframe");
      if (iframe) {
        iframe.contentWindow.postMessage("onClickBack", "*");
      }
    });
  }, []);

  async function getLink() {
    try {
      const funct = httpsCallable(functions, "getCasinoLink");
      const res = await funct();
      const url = res.data.link;
      console.log(await auth.currentUser.getIdToken());

      setLink(url);
    } catch (error) {
      showErrorNotification("Error");
    }
  }
  if (!link) {
    return <LoadingScreen />;
  }
  return (
    <>
      <iframe
        id="casino-iframe"
        width={"100%"}
        height={window.innerHeight - 3}
        style={{ border: "none" }}
        src={link}
      />
    </>
  );
}
