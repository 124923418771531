import { useEffect, useRef, useState } from "react";
import {
  BackToHomeBtn,
  InputField,
  LoadingScreen,
  Padding,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
  TextButton,
  generateAlphaNumericCode,
} from "../compnents/otherCOmps";
import {
  Timestamp,
  addDoc,
  arrayRemove,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import reloadImg from "../assets/reload.png";
import { telegramID, tgUsernameGlob } from "../aStartingPage";
import copyIcon from "../assets/copy.png";
import { Howl } from "howler";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BaseInfo,
  ColorPalette,
  getUserBalances,
  GroupsDoc,
} from "../global/const";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";
//import { getRealBalance } from "../global/util";
import { httpsCallable } from "firebase/functions";
import { copyString, formatTimeAgoMinutes } from "../global/util";
import { getPriceToken } from "../global/apis";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import Slider from "@mui/material/Slider";

import notificaSOund from "../audioFiles/notification.wav";
import { ContainerBorder, PageModal, TabButton } from "../compnents/compes";
import { RiFileCopyFill, RiFileCopyLine } from "@remixicon/react";
import { signInWithCustomToken } from "firebase/auth";
import { supabase } from "../supabase";

let invitedFromChallenges = [];

export default function ChallengesPage() {
  const [listRooms, setListRooms] = useState([]);
  const [listRoomsMine, setListRoomsMine] = useState([]);

  const nav = useNavigate();

  const [tabSelected, setTabSelected] = useState("other");

  const [challengeToSend, setChallengeToSend] = useState();
  const [friends, setFriends] = useState();

  const [invited, setInvited] = useState(invitedFromChallenges);

  useEffect(() => {
    getListRooms();
    getListRoomsMine();
  }, []);

  useEffect(() => {
    if (challengeToSend && !friends) {
      getDocs(collection(db, `users/${auth.currentUser.uid}/friends`)).then(
        (docs) => setFriends(docs.docs.map((d) => d.data()))
      );
    }
  }, [challengeToSend]);

  async function getListRooms() {
    setListRooms([]);
    const gameNameID = "games";

    let q = query(
      collection(db, gameNameID),
      where("state", "==", "waiting"),
      where("isOpen", "==", true),
      where("differtPlayerFinished", "==", true),
      where("players_count", "==", 1),
      where("createdBy", "!=", auth.currentUser.uid),
      //  orderBy("creationDate", "desc"),
      limit(15)
    );

    const resList = await getDocs(q);
    let rooms = [];
    resList.docs.forEach((element) => {
      rooms.push({
        ...element.data(),
        id: element.id,
      });
    });
    rooms.sort((a, b) => a.creationDate - b.creationDate);
    setListRooms(rooms);
  }

  async function getListRoomsMine() {
    setListRoomsMine([]);
    const gameNameID = "games";

    let q = query(
      collection(db, gameNameID),
      where("differtPlayerFinished", "==", true),
      where("players_count", "==", 1),
      where("createdBy", "==", auth.currentUser.uid),
      //  orderBy("creationDate", "desc"),
      limit(15)
    );

    const resList = await getDocs(q);
    let rooms = [];
    resList.docs.forEach((element) => {
      rooms.push({
        ...element.data(),
        id: element.id,
      });
    });
    rooms.sort((a, b) => a.creationDate - b.creationDate);
    setListRoomsMine(rooms);
  }

  function goToLobbyRoom(code, gameUrl) {
    nav("/lobby", {
      state: {
        invitedToGame: {
          code: code,
          gameUrl: gameUrl,
        },
      },
    });
  }
  function SingleRoom({ room, mine = false }) {
    return (
      <ContainerBorder
        cursorPointer={!mine}
        padding="10px 8px"
        onClick={mine ? null : () => goToLobbyRoom(room.code, room.gameUrl)}
      >
        <RRow
          horizontalAlign="space-between"
          verticalAlignment="top"
          height={"100%"}
        >
          <RRow height={"100%"}>
            <img src={"gameIcons/" + room.gameUrl + ".png"} width={"60px"} />
            <RColumn
              horizontalAlign="start"
              height={"100%"}
              verticalAlignment="top"
            >
              <RTitile>{BaseInfo.gamesName[room.gameUrl]}</RTitile>
              <Space height="4px" />
              {!mine && (
                <RText weight="bold" size="16px">
                  by {room.players?.[0]?.username}
                </RText>
              )}
              {room.level && <RText size="14px">Level {room.level}</RText>}
              {mine && (
                <RRow>
                  <RText color="grey">
                    {room.isOpen ? "Open to all" : "Private"}
                  </RText>
                  <SpaceHorizontal />
                  {/* <div
                    style={{ cursor: "pointer", display: "flex" }}
                    onClick={() => copyString(room.code, "Code copied")}
                  >
                    <RiFileCopyLine color="grey" size={"16px"} />
                    <RText color="grey">{room.code}</RText>
                  </div> */}
                  <SecondaryBtn
                    text={"Send"}
                    onClick={() => setChallengeToSend(room)}
                  />
                </RRow>
              )}
            </RColumn>
          </RRow>
          <RColumn horizontalAlign="end">
            <RText align={"end"} size="15px" color="grey">
              {formatTimeAgoMinutes(room.creationDate)}
            </RText>
            <Space height="8px" />
            <RText weight="bold" size="22px">
              {room.price}
              {room.bet_type === "points" ? " pts" : "$"}
            </RText>
          </RColumn>
        </RRow>
      </ContainerBorder>
    );
  }
  function RoomList() {
    if (tabSelected === "other")
      return (
        <>
          {listRooms.length === 0 && (
            <RText align={"center"}>No challenges open, create one!</RText>
          )}
          {listRooms.map((room, index) => (
            <SingleRoom room={room} key={index} />
          ))}
        </>
      );
    if (tabSelected === "mine")
      return (
        <>
          {listRoomsMine.length === 0 && (
            <RText align={"center"}>
              No challenges created by you are currently waiting for an
              opponent.
            </RText>
          )}
          {listRoomsMine.map((room, index) => (
            <SingleRoom room={room} key={index} mine />
          ))}
        </>
      );
  }

  async function invite(id, type) {
    if (invitedFromChallenges.includes(String(id) + challengeToSend.id)) {
      showErrorNotification("Already invited");
      return;
    }
    invitedFromChallenges.push(String(id) + challengeToSend.id);
    setInvited((p) => [...p, String(id) + challengeToSend.id]);
    showSuccessNotification("Invited!", 1000, "bottom-right");
    const funct = httpsCallable(functions, "inviteFriGroup");
    await funct({
      gameID: challengeToSend.id,
      invitedId: id,
      type,
    });
  }
  function handleReload() {
    getListRooms();
    getListRoomsMine();
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      {challengeToSend && (
        <PageModal onClose={() => setChallengeToSend(null)}>
          <RRow horizontalAlign="center">
            <img
              src={`gameIcons/${challengeToSend.gameUrl}.png`}
              width={"64px"}
            />
            <RTitile>{challengeToSend.gameName}</RTitile>
          </RRow>
          <RRow horizontalAlign="center">
            <RText weight="bold" size="22px">
              {challengeToSend.price}
              {challengeToSend.bet_type === "points" ? " pts" : "$"}
            </RText>
            {challengeToSend.level && (
              <>
                <SpaceHorizontal />
                <RText color="grey"> level {challengeToSend.level}</RText>
              </>
            )}
          </RRow>
          <RText color="grey">Groups</RText>
          {GroupsDoc.groups.map((g) => (
            <ContainerBorder
              gradientBg={invited.includes(String(g.id) + challengeToSend.id)}
              key={g.id}
              onClick={() => invite(g.id, "group")}
            >
              <RRow>
                <img
                  src={g.photo}
                  height={"42px"}
                  width={"42px"}
                  style={{ borderRadius: "100%" }}
                />
                <SpaceHorizontal />
                <RText>{g.name}</RText>
              </RRow>
            </ContainerBorder>
          ))}
          <Space />

          <RText color="grey">Friends</RText>
          {friends?.map((f) => (
            <ContainerBorder
              gradientBg={invited.includes(String(f.uid) + challengeToSend.id)}
              key={f.uid}
              onClick={() => invite(f.uid, "friend")}
            >
              <RRow>
                <img
                  src={f.avatarUrl}
                  height={"42px"}
                  width={"42px"}
                  style={{ borderRadius: "100%" }}
                />
                <SpaceHorizontal />
                <RText>{f.username}</RText>
              </RRow>
            </ContainerBorder>
          ))}
        </PageModal>
      )}
      <Space />
      <RRow horizontalAlign="space-between">
        <RTitile align={"center"}>Challenges</RTitile>
        <img onClick={handleReload} src={reloadImg} width={"22px"} />
      </RRow>
      <Space />
      <RRow>
        <TabButton
          width={"40%"}
          selected={tabSelected === "other"}
          onClick={() => setTabSelected("other")}
        >
          Available
        </TabButton>
        <TabButton
          width={"40%"}
          selected={tabSelected === "mine"}
          onClick={() => setTabSelected("mine")}
        >
          Mine waiting
        </TabButton>
      </RRow>
      {tabSelected === "mine" && (
        <>
          <Space />
          <RText color="grey" size="14px">
            If no one plays it within 24 hours you will be refunded.
          </RText>
        </>
      )}
      <Space />

      <RoomList />
      <Space height="120px" />
    </>
  );
}
