import {
  InputField,
  LoadingScreen,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import copyIcon from "../assets/copy.png";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import { BaseInfo, ColorPalette, StatusInfo, isTest } from "../global/const";
import { format } from "date-fns";
import { httpsCallable } from "firebase/functions";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { copyString, formatNumber } from "../global/util";
import { redColor } from "../profile/profilePage";
import {
  BuyBtns,
  ContainerBorder,
  MaxWidthContainer,
} from "../compnents/compes";
import backIcon from "../assets/previous.png";
import useWindowWidth from "../util";
import axios from "axios";
import WalletDeposit from "./deposit";
import { useGameType } from "../theme";
import { RiRestartLine } from "@remixicon/react";
import WalletWidraw from "./widthdraw";
import HistoryWallet from "./historyWallet";

export default function WalletPage({ wallet }) {
  const [lastDeposits, setLastDepoists] = useState([]);
  const [isDeposit, setIsDeposit] = useState(true);
  const [isWidthrawing, setIsWidrawing] = useState(false);
  const [widAmount, setWidAmount] = useState("");
  const [widAddress, setWidAddress] = useState("");
  const [selectedChain, setSelectedChain] = useState(BaseInfo.ethChainID);
  const [tokenSelected, setTokenSelected] = useState(null);
  const [lastWid, setLastWid] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const windowWidth = useWindowWidth();
  const [pageState, setPageState] = useState("init");

  const { userBalances } = useGameType();
  const fixedFee = {
    [BaseInfo.ethChainID]: 0.002,
    [BaseInfo.arbChainID]: 0.001,
    [BaseInfo.gameTokenAddress]: 0.006,
  };

  const minEth = 0.003;
  const minGhub = 500;
  useEffect(() => {
    getDesposits();
    getWid();
  }, []);

  async function getDesposits() {
    const depositsRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "deposits"
    );
    const q = query(depositsRef, orderBy("date", "desc"), limit(5));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return;
    let newDocs = [];
    querySnapshot.docs.forEach((doc) => {
      newDocs.push(doc.data());
    });
    setLastDepoists(newDocs);
  }

  async function getWid() {
    const depositsRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "withdrawals"
    );
    const q = query(depositsRef, orderBy("date", "desc"), limit(5));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return;
    let newDocs = [];
    querySnapshot.docs.forEach((doc) => {
      newDocs.push(doc.data());
    });
    setLastWid(newDocs);
  }
  function copyAddress() {
    navigator.clipboard.writeText(wallet);
    toast.success("Address copied", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
    });
  }

  function SingleDeposit({ deposit }) {
    let txLink;
    if (deposit.chainID === BaseInfo.ethChainID) {
      txLink = "https://etherscan.io/tx/";
    } else if (deposit.chainID === BaseInfo.arbChainID) {
      txLink = "https://arbiscan.io/tx/";
    }
    return (
      <div style={{ width: "100%" }}>
        <ContainerBorder>
          <RColumn
            width={"100%"}
            verticalAlignment="start"
            horizontalAlign="start"
          >
            <RRow width={"100%"} horizontalAlign="space-between">
              <RText>{format(deposit.date, "iii d MMM HH:mm")}</RText>
              <RText color={deposit.confirmed ? "green" : "orange"}>
                {deposit.confirmed ? "Confirmed" : "Pending"}
              </RText>
            </RRow>
            <Space height="10px" />
            <RText weight="bold" size="20px">
              {deposit.valueWithDecimals}{" "}
              {BaseInfo.chainTokensData[
                deposit.erc20Contract ?? deposit.chainID
              ]?.symbol ?? "-"}
            </RText>
            <RText>{BaseInfo.chainTokensData[deposit.chainID].name}</RText>
            <RText size="17px">
              from {deposit.from.slice(0, 10)}...{deposit.from.slice(-10)}
            </RText>
            {deposit.hash && (
              <div
                style={{
                  color: "#c4c4c4",
                  width: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                <a href={txLink + deposit.hash} target="_blank">
                  hash {deposit.hash}
                </a>
              </div>
            )}
          </RColumn>
        </ContainerBorder>
      </div>
    );
  }
  function SingleWId({ wid }) {
    let txLink;
    if (wid.chainID === BaseInfo.ethChainID) {
      txLink = "https://etherscan.io/tx/";
    } else if (wid.chainID === BaseInfo.arbChainID) {
      txLink = "https://arbiscan.io/tx/";
    }
    return (
      <div style={{ width: "100%" }}>
        <ContainerBorder>
          <RColumn
            width={"100%"}
            verticalAlignment="start"
            horizontalAlign="start"
          >
            <RRow width={"100%"} horizontalAlign="space-between">
              <RText>{format(wid.date, "iii d MMM HH:mm")}</RText>
              <RText color={wid.confirmed ? "green" : "orange"}>
                {wid.confirmed ? "Confirmed" : "Pending"}
              </RText>
            </RRow>
            <Space height="10px" />
            <RText weight="bold" size="20px">
              {wid.amountDecimal}{" "}
              {BaseInfo.chainTokensData[
                wid.native ? wid.chainID : wid.tokenAddress
              ]?.symbol ?? "-"}
            </RText>
            <RText>
              fee: {fixedFee[wid.native ? wid.chainID : wid.tokenAddress]} ETH
            </RText>
            <RText>{BaseInfo.chainTokensData[wid.chainID].name}</RText>
            <RText size="17px">
              to {wid.toAddress.slice(0, 10)}...{wid.toAddress.slice(-10)}
            </RText>
            {wid.hash && (
              <div
                style={{
                  color: "#c4c4c4",
                  width: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                <a href={txLink + wid.hash} target="_blank">
                  hash {wid.hash}
                </a>
              </div>
            )}
          </RColumn>
        </ContainerBorder>
      </div>
    );
  }

  function Deposits() {
    return (
      <>
        {lastDeposits.length === 0 && <RText>No deposits</RText>}
        {lastDeposits.map((dep, ind) => (
          <SingleDeposit key={ind} deposit={dep} />
        ))}
      </>
    );
  }
  function handleInputChange(event) {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, "");
    // Assicurarsi che ci sia solo un punto decimale
    const dotCount = inputValue.split(".").length - 1;
    if (dotCount > 1) {
      inputValue = inputValue.slice(0, -1);
    }

    setWidAmount(inputValue);
  }
  function Widthdrawal() {
    return (
      <>
        {lastWid.length === 0 && <RText>No withdrawals</RText>}
        {lastWid.map((dep, ind) => (
          <SingleWId key={ind} wid={dep} />
        ))}
      </>
    );
  }

  async function preleva() {
    //console.log(parseFloat(widAmount));
    if (widAddress.length < 5) {
      showErrorNotification("Enter an address");
      return;
    }
    try {
      setIsLoading(true);
      const funct = httpsCallable(functions, "withdrawFunds");
      const dat = {
        chainID: selectedChain,
        native: tokenSelected === null,
        tokenAddress: tokenSelected,
        toAddress: widAddress,
        amount: parseFloat(widAmount),
      };
      //console.log(dat);
      const res = await funct(dat);
      //console.log(res.data);
    } catch (error) {
      //console.log(error);
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
  }
  function Head({ children }) {
    return (
      <RRow>
        <img
          src={backIcon}
          width={"16px"}
          onClick={() => {
            setPageState("init");
            setTokenSelected(null);
          }}
        />
        <SpaceHorizontal />
        <RTitile>{children}</RTitile>
      </RRow>
    );
  }

  if (pageState === "history") {
    return <HistoryWallet Head={Head} />;
  }
  if (pageState === "widthdraw") {
    return <WalletWidraw Head={Head} />;
  }
  if (pageState === "deposit") {
    return <WalletDeposit Head={Head} />;
  }
  function Btns() {
    const isMobile = windowWidth < 500;
    return (
      <RRow
        horizontalAlign={isMobile ? "space-between" : "start"}
        width={isMobile ? "100%" : null}
      >
        <div
          onClick={() => setPageState("deposit")}
          style={{
            width: "115px",
            textAlign: "center",
            padding: "10px 2px",
            color: "white",
            backgroundColor: ColorPalette.accentColor2,
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          Deposit
        </div>
        {!isMobile && <SpaceHorizontal />}
        <div
          onClick={() => setPageState("widthdraw")}
          style={{
            width: "115px",
            textAlign: "center",
            padding: "10px 2px",
            color: "white",
            backgroundColor: ColorPalette.accentColor2Bg,
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          Withdraw
        </div>
        {!isMobile && <SpaceHorizontal />}
        <div
          onClick={() => setPageState("history")}
          style={{
            width: "115px",
            textAlign: "center",
            padding: "10px 2px",
            color: "white",
            backgroundColor: ColorPalette.accentColor2Bg,
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          History
        </div>
      </RRow>
    );
  }

  const balancesAmount = BaseInfo.baseInfoDoc.coinSupported.map((v) => ({
    ...v,
    tkn_amount: userBalances?.balances?.[v.symbol.toLowerCase()]?.amount ?? 0,
    usd_amount:
      userBalances?.balances?.[v.symbol.toLowerCase()]?.usd_amount ?? 0,
  }));

  balancesAmount.sort((a, b) => {
    if (b.usd_amount !== a.usd_amount) {
      return b.usd_amount - a.usd_amount;
    }
    return b.tkn_amount - a.tkn_amount;
  });
  return (
    <>
      <Space />
      <RColumn horizontalAlign="start" width={"100%"}>
        <RRow horizontalAlign="space-between" width={"100%"}>
          <RTitile>{formatNumber(userBalances.total_usd, 2)} USD</RTitile>

          {windowWidth > 500 && <Btns />}
        </RRow>
        <Space height="8px" />
        {windowWidth < 500 && <Btns />}
        <Space />
        <BuyBtns />
        {balancesAmount.map((v, i) => (
          <ContainerBorder key={v.symbol} padding="10px">
            <RRow horizontalAlign="space-between">
              <RRow>
                <img src={v.img} width={"22px"} />
                <SpaceHorizontal />
                <RColumn horizontalAlign="start">
                  <RText size="18px">{v.symbol}</RText>
                  <Space height="4px" />
                  <RText size="16px" color={"grey"}>
                    {v.name}
                  </RText>
                </RColumn>
              </RRow>
              <RColumn horizontalAlign="end">
                <RText size="15px">{formatNumber(v.tkn_amount, 5)}</RText>
                <RText color="grey" size="14px">
                  ${formatNumber(v.usd_amount, 2)}
                </RText>
              </RColumn>
            </RRow>
          </ContainerBorder>
        ))}

        <Space height="140px" />
      </RColumn>
      {isLoading && <LoadingScreen />}
    </>
  );
}
