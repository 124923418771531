import { useEffect, useState } from "react";
import {
  InputField,
  LoadingScreen,
  RColumn,
  RGrid,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { supabase } from "../supabase";
import axios from "axios";
import { auth } from "../firebase";
import casinoBannImg from "../assets/Banner Casino.jpg";
import { useGameType } from "../theme";
import { BaseInfo, ColorPalette, UserInfo } from "../global/const";
import WalletPage from "../wallet/walletPgae";
import { RiArrowGoBackLine, RiArrowLeftFill } from "@remixicon/react";
import {
  DropdownMenu,
  DropdownMenuItem,
  MaxWidthContainer,
} from "../compnents/compes";
import { debounce } from "lodash";

export default function QTLobbyPage() {
  const [games, setGames] = useState([]);

  const {
    setGameType,
    userBalances,
    updateUserBalances,
    qtSelectedCurrency,
    setQtSelectedCurrency,
  } = useGameType();

  const [state, setState] = useState("home");

  // Aggiungi le nuove categorie al filtro
  const filterGame = [
    { name: "All", query: null },
    { name: "Slot", query: "SLOT" },
    { name: "Virtual football", query: "VIRTUAL_FOOTBALL" },
    { name: "Table game", query: "TABLEGAME" },

    { name: "Instant Win", query: "INSTANTWIN" },
    { name: "Lottery", query: "LOTTERY" },
    { name: "Virtual Sports", query: "VIRTUAL_SPORTS" },
    { name: "Scratch card", query: "SCRATCH_CARD" },
    { name: "Poker", query: "POKER" },
  ];

  const [currentGame, setCurrentGame] = useState({
    url: "",
    active: false,
    loading: false,
  });

  const [selectedFilter, setSelectedFilter] = useState(filterGame[0].query);
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedGetGames = debounce(getGames, 300); // 300 ms di ritardo

  const balancesAmount = BaseInfo.baseInfoDoc.coinSupported
    .filter((x) => x.symbol.toLowerCase() !== "ghub")
    .map((v) => ({
      ...v,
      tkn_amount: userBalances?.balances?.[v.symbol.toLowerCase()]?.amount ?? 0,
      usd_amount:
        userBalances?.balances?.[v.symbol.toLowerCase()]?.usd_amount ?? 0,
    }));
  balancesAmount.sort((a, b) => {
    if (b.usd_amount !== a.usd_amount) {
      return b.usd_amount - a.usd_amount;
    }
    return b.tkn_amount - a.tkn_amount;
  });
  useEffect(() => {
    debouncedGetGames();
    // Cleanup function per annullare il debounce quando il componente si smonta
    return () => {
      debouncedGetGames.cancel();
    };
  }, [searchTerm, selectedFilter]);

  useEffect(() => {
    updateUserBalances();
  }, [state, currentGame]);

  async function getGames() {
    let query = supabase.from("qt_games").select("id, name, images").limit(300);

    if (selectedFilter && selectedFilter !== "ALL") {
      query = query.ilike("category", `%${selectedFilter}%`);
    }
    if (searchTerm) {
      query = query.ilike("name", `%${searchTerm}%`);
    }
    const { data, error } = await query;

    if (error) {
      console.log("Error fetching games:", error);
    } else {
      setGames(data);
    }
  }

  function handleSearchChange(e) {
    setSearchTerm(e.target.value);
  }

  async function getGameUrl(id) {
    const token = await auth.currentUser.getIdToken();
    setCurrentGame({ url: "", active: false, loading: true });

    try {
      const res = await axios.post(
        "https://uno.thegamehub.gg:3009/getgameurl",
        {
          gameID: id,
          userToken: token,
          uid: auth.currentUser.uid,
          fromTg: true,
        },
        {
          headers: { sign: "7qf1oyipq5m7od27n75kli2y8c7iek3x" },
        }
      );
      console.log(res.data.url);
      setCurrentGame({
        url: res.data.url + "&hideSplash=true",
        active: true,
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  function SingleGame({ game }) {
    return (
      <div
        style={{
          width: "30%",
          borderRadius: "12px",
          maxWidth: "130px",
          cursor: "pointer",
        }}
        onClick={() => getGameUrl(game.id)}
      >
        <img
          src={game.images?.[2]?.url ?? "no"}
          width={"100%"}
          style={{ borderRadius: "12px" }}
        />
      </div>
    );
  }

  if (currentGame?.active) {
    return (
      <>
        <div style={{ height: "40px" }}>
          <RRow height={"100%"} verticalAlignment="center">
            <SpaceHorizontal />
            <SecondaryBtn
              text={"close"}
              onClick={() => setCurrentGame({ active: false, url: "" })}
            />
          </RRow>
        </div>
        <iframe
          src={currentGame.url}
          width={"100%"}
          height={window.innerHeight - 45}
          style={{ border: "none" }}
        />
      </>
    );
  }

  async function onSelectCurr(currency) {
    try {
      const usTkn = await auth.currentUser.getIdToken();
      await axios.post(
        "https://uno.thegamehub.gg:3009/setSelectedCurrency",
        { currency: currency },
        {
          headers: {
            sign: "7qf1oyipq5m7od27n75kli2y8c7iek3x",
            "user-token": usTkn,
          },
        }
      );
      setQtSelectedCurrency(currency);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Space />
      <MaxWidthContainer>
        <RRow horizontalAlign="space-between">
          <img
            alt=""
            style={{ cursor: "pointer" }}
            src="logo.png"
            height={"58px"}
            onClick={() => setGameType("init")}
          />

          <RRow>
            <DropdownMenu
              img={
                <img
                  src={
                    BaseInfo.baseInfoDoc.coinSupported.find(
                      (c) =>
                        c.symbol.toLowerCase() ===
                        qtSelectedCurrency.toLowerCase()
                    ).img
                  }
                  width={"18px"}
                  alt=""
                />
              }
              buttonLabel={
                "$" +
                balancesAmount
                  .find(
                    (c) =>
                      c.symbol.toLowerCase() ===
                      qtSelectedCurrency.toLowerCase()
                  )
                  .usd_amount.toFixed(2)
              }
              onItemClick={(a) => onSelectCurr(a.id)}
            >
              {balancesAmount.map((val) => (
                <DropdownMenuItem key={val.symbol} id={val.symbol}>
                  <RRow horizontalAlign="space-between" width={"100%"}>
                    <RRow>
                      <img src={val.img} alt="" width={"22px"} />
                      <SpaceHorizontal />
                      <RText>{val.symbol.toUpperCase()}</RText>
                    </RRow>

                    <RText>${val.usd_amount.toFixed(2)}</RText>
                  </RRow>
                </DropdownMenuItem>
              ))}
            </DropdownMenu>
            {/*  <div
              style={{
                backgroundColor: ColorPalette.accentColor2,
                padding: "6px 12px",
                borderRadius: "6px",
              }}
            >
              <RText>${userBalances.total_usd.toFixed(2)}</RText>
            </div> */}
            <div
              onClick={() => setState("wallet")}
              style={{
                cursor: "pointer",
                backgroundColor: "#D0932E",
                padding: "6px 8px",
                borderRadius: "8px",
                marginLeft: "-8px",
                zIndex: 10,
              }}
            >
              <RText size="16px">Deposit</RText>
            </div>
          </RRow>
        </RRow>

        <Space height="12px" />
        {state !== "home" && (
          <>
            <div onClick={() => setState("home")} style={{ cursor: "pointer" }}>
              <RRow>
                <RiArrowLeftFill color="white" />
                <RText>back</RText>
              </RRow>
            </div>
          </>
        )}
        {state === "home" && (
          <>
            {currentGame?.loading && <LoadingScreen />}
            <img
              src={casinoBannImg}
              style={{ borderRadius: "12px" }}
              width={"100%"}
            />
            <Space />
            <InputField
              placeHolder={"Search"}
              value={searchTerm}
              setValue={handleSearchChange}
              padding="12px 12px"
            />
            <div
              style={{
                backgroundColor: ColorPalette.accentColor2Bg,
                width: "100%",
                minWidth: "320px",

                borderRadius: "12px",
              }}
            >
              <div style={{ padding: "6px 6px" }}>
                <RGrid horizontalAlign={"space-between"} width={"100%"}>
                  {filterGame.map((filter) => (
                    <div
                      key={filter.query}
                      onClick={() => setSelectedFilter(filter.query)} // Cambia il filtro quando cliccato
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          selectedFilter === filter.query
                            ? ColorPalette.accentColor2
                            : "",
                        padding: "4px 8px",
                        borderRadius: "8px",
                      }}
                    >
                      <RText>{filter.name}</RText>
                    </div>
                  ))}
                </RGrid>
              </div>
            </div>
            <Space />
            <RGrid horizontalAlign="space-between">
              {games.map((g) => (
                <SingleGame game={g} key={g.id} />
              ))}
            </RGrid>
          </>
        )}
        {state === "wallet" && (
          <>
            <WalletPage />
          </>
        )}
      </MaxWidthContainer>
    </>
  );
}
