import { toast } from "react-toastify";

export const showErrorNotification = (errorMessage, time = 3000) => {
  toast.error(errorMessage, {
    position: "top-right",
    autoClose: time,
    hideProgressBar: false,
  });
};
export const showSuccessNotification = (
  errorMessage,
  time = 1500,
  position = "top-right"
) => {
  toast.success(errorMessage, {
    position: position,
    autoClose: time,
    hideProgressBar: false,
  });
};
