import React from "react";
import {
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { ColorPalette } from "../global/const";
import LoadingIcons from "react-loading-icons";
import { levelImgs } from "../home/tabController";

export function Leaderboard2({
  players,
  gameName,
  userP,
  scoreSuffix = "",
  scoreColor,
}) {
  const topThree = [players[1], players[0], players[2]];
  const top3Colors = ["#78C5DB", "#F8F153", "#D25C35"];
  const others = players.slice(3);

  if (players[0] === null) {
    return (
      <RColumn
        width={"100%"}
        horizontalAlign="center"
        verticalAlignment="start"
      >
        {" "}
        <LoadingIcons.ThreeDots width={"32px"} />
      </RColumn>
    );
  }
  return (
    <RColumn width={"100%"} horizontalAlign="center" verticalAlignment="start">
      <div
        style={{
          maxWidth: "600px",
          width: "90vw",
        }}
      >
        <Space height="20px" />

        <Space height="8px" />
        <RRow horizontalAlign="center">
          <img src="logo.png" width={"52px"} />
          <SpaceHorizontal />
          <RColumn horizontalAlign="start">
            <RText size="18px" weight="bold">
              {gameName} Leaderboard
            </RText>
            <Space height="4px" />
            <RText color="grey" size="16px">
              thegamehub.io
            </RText>
          </RColumn>
        </RRow>

        <Space height="20px" />
        {/* Top 3 Players */}
        <RRow horizontalAlign="space-between" width="100%">
          {topThree.map((player, index) => (
            <RColumn
              key={index}
              horizontalAlign="center"
              verticalAlignment="end"
              height={"200px"}
              bgColor={"red"}
              width={"30%"}
            >
              {!player && (
                <div
                  style={{
                    width: index === 1 ? "110px" : "70px", // Bigger for the center player
                    height: index === 1 ? "110px" : "70px",
                  }}
                ></div>
              )}
              {player && (
                <>
                  <div
                    style={{
                      width: index === 1 ? "110px" : "70px", // Bigger for the center player
                      height: index === 1 ? "110px" : "70px",
                      borderRadius: "50%",
                      background: `url(${
                        player?.avatarUrl ??
                        `https://api.dicebear.com/9.x/pixel-art/png?scale=80&backgroundColor=d1d4f9,c0aede,b6e3f4,ffd5dc,ffdfbf&seed=${
                          player?.id ?? "gg"
                        }`
                      }) no-repeat center center/cover`,
                      //   border: index === 1 ? "3px solid whitde" : "none", // Optional border for center player
                    }}
                  />
                  <Space height="8px" />
                  <RTitile>{player?.rank ?? ""}</RTitile>
                  <RRow>
                    {player?.usrLvl > 0 && (
                      <img width={"22px"} src={levelImgs[player.usrLvl]} />
                    )}
                    <RText
                      maxlines={2}
                      align={"center"}
                      size="15px"
                      weight="bold"
                    >
                      {player?.username ?? ""}
                    </RText>
                  </RRow>

                  <Space height="4px" />
                  <RText size="18px" color={scoreColor ?? top3Colors[index]}>
                    {scoreSuffix}
                    {player?.score ?? ""}
                  </RText>
                </>
              )}
            </RColumn>
          ))}
        </RRow>
        <Space height="20px" />
        {/* Other Players */}
        <RColumn width="100%" horizontalAlign="center">
          {others.map((player, index) => (
            <div key={player.username} style={{ width: "100%" }}>
              <RRow horizontalAlign="space-between" width="100%">
                <RRow>
                  <RTitile>{player.rank}</RTitile>
                  <SpaceHorizontal width={player.rank > 9 ? "12px" : "22px"} />
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      background: `url(${
                        player.avatarUrl ??
                        `https://api.dicebear.com/9.x/pixel-art/png?scale=80&backgroundColor=d1d4f9,c0aede,b6e3f4,ffd5dc,ffdfbf&seed=${
                          player?.id ?? "gg"
                        }`
                      }) no-repeat center center/cover`,
                    }}
                  />
                  <SpaceHorizontal width="12px" />
                  <RText size="16px">{player.username}</RText>
                </RRow>
                <RText size="16px">
                  {" "}
                  {scoreSuffix}
                  {player.score}
                </RText>
              </RRow>
              {/* Separator */}
              {index < others.length - 1 && (
                <div
                  style={{
                    height: "1px",
                    width: "100%",
                    maxWidth: "600px",
                    backgroundColor: "#444",
                    margin: "8px 0",
                  }}
                />
              )}
            </div>
          ))}
        </RColumn>
        {/* user */}

        {userP && (
          <React.Fragment>
            <Space height="8px" />
            <RTitile>...</RTitile>
            <Space height="8px" />
            <RRow horizontalAlign="space-between" width="100%" maxWidth="600px">
              <RRow>
                <RTitile>{userP.rank}</RTitile>
                <SpaceHorizontal width="12px" />
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    background: `url(${userP.avatarUrl}) no-repeat center center/cover`,
                  }}
                />
                <SpaceHorizontal width="12px" />
                <RText size="16px">{userP.username}</RText>
              </RRow>
              <RText size="16px">
                {" "}
                {scoreSuffix}
                {userP.score}
              </RText>
            </RRow>
          </React.Fragment>
        )}
      </div>
    </RColumn>
  );
}
