import { BadgeWhiteBg, ContainerBorder } from "../compnents/compes";
import {
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { BaseInfo, ColorPalette } from "../global/const";
import { getLevelInd } from "../global/util";
import { useGameType } from "../theme";

import level1Img from "../assets/level1.png";
import level2Img from "../assets/level2.png";
import level3Img from "../assets/level3.png";

export default function Level2Page() {
  const { userBalances } = useGameType();
  const levelsImg = [null, level1Img, level2Img, level3Img];

  function SingleC({ tit, desc }) {
    return (
      <>
        <Space height="1px" />
        <RRow>
          <div style={{ width: "62px" }}>
            <RTitile size="18px">{tit}</RTitile>
          </div>
          <SpaceHorizontal />
          <RText color="grey">{desc}</RText>
        </RRow>
      </>
    );
  }
  return (
    <>
      <Space />
      <RTitile>LEVELS</RTitile>
      <Space height="8px" />
      <RText size="17px">By GHUB amount in wallet</RText>
      <Space height="8px" />
      <Space />
      {BaseInfo.levels2.map((level, i) => (
        <ContainerBorder gradientBg={getLevelInd(userBalances) === i}>
          <RRow width={"100%"}>
            {/* <RTitile size="26px">{i + 1}</RTitile>
            <SpaceHorizontal /> */}
            {/*  {levelsImg[i] ? (
              <img src={levelsImg[i]} width={"25px"} />
            ) : (
              <div style={{ width: "25px" }}></div>
            )} 
            <SpaceHorizontal />
            */}
            <RTitile>{level.name}</RTitile>
            <SpaceHorizontal />
            <RText color={ColorPalette.accentColor}>
              {">"}
              {level.GHUB_required} $GHUB
            </RText>
          </RRow>
          <Space height="12px" />
          <SingleC
            tit={level.daily_free_P2E_attempts}
            desc={"daily free attempts"}
          />
          <SingleC
            tit={level.extra_P2E_attempts_price + "$"}
            desc={"extra free attempts"}
          />
          <SingleC
            tit={level.cashback_PvP_games + "%"}
            desc={"cashback pvp games"}
          />
          <SingleC
            tit={level.weekly_lottery_tickets}
            desc={"weekly lottery tickets"}
          />
          <SingleC
            tit={level.referral_percentage + "%"}
            desc={"referral share"}
          />
          <SingleC
            tit={level.GHUB_holders_lounge_access ? "Yes" : "No"}
            desc={"GHUB lounge access"}
          />
          <SingleC
            tit={level.revenue_share ? "Yes" : "No"}
            desc={"revenue share"}
          />
        </ContainerBorder>
      ))}
      <Space height="120px" />
    </>
  );
}
