import { useLocation, useNavigate } from "react-router-dom";
import { BaseInfo, ColorPalette } from "../global/const";
import { auth, db, functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import {
  PrimaryBtn,
  RColumn,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
} from "../compnents/otherCOmps";
import { doc, onSnapshot } from "firebase/firestore";
import { getIdToken } from "firebase/auth";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import SpinningCircles from "react-loading-icons/dist/esm/components/spinning-circles";

export default function DiffertGame() {
  const location = useLocation();

  const { gameID, level, url, firstToPlay, gameCode } = location.state;
  let [h, setH] = useState(window.innerHeight);
  const nav = useNavigate();

  const [gameFinished, setGameFinished] = useState(false);
  const [scoreSublitted, setScoreSubmitted] = useState(false);

  const docGameRef = doc(db, "games", gameID);

  const linkGame = !BaseInfo.games[url].marketJS
    ? BaseInfo.games[url].link
    : BaseInfo.games[url].link +
      `?user_id=${auth.currentUser.uid}&game_id=${url}&tournament_id=${gameID}`;
  let score;
  let userTokenId;
  const searchParams = new URLSearchParams(window.location.search);
  const fromInlineMode =
    searchParams.get("gameUrl") || searchParams.get("inline") === "btn";

  const handleResize = () => {
    setH(window.innerHeight);

    //p2e

    if (window.innerWidth < 600) return;

    var iframe_id = "gameFrame";

    var gameWindowBox = document.getElementById(iframe_id);
    if (!gameWindowBox) return;

    if (!BaseInfo.games[url].marketJS) {
      gameWindowBox.style.width = window.innerWidth + "px";
      gameWindowBox.style.height = window.innerHeight + "px";
      return;
    }

    var offset_multiplier = 0.99;
    var min_width = 320; // Minimum width for the iframe
    var original_game_width = 800;

    var original_game_height = 600;
    var max_height_percentage = 0.99;

    var aspect_ratio = original_game_width / original_game_height;
    var max_width = window.innerWidth * offset_multiplier;
    var max_height = window.innerHeight * max_height_percentage;

    var game_width = Math.max(
      min_width,
      Math.min(max_width, window.innerWidth)
    );

    var game_height = game_width / aspect_ratio;

    if (game_height > max_height) {
      game_height = max_height;

      game_width = game_height * aspect_ratio;
    }
    gameWindowBox.style.width = game_width + "px";

    gameWindowBox.style.height = game_height + "px";
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    const iframe = document.getElementById("gameFrame");
    if (iframe && !BaseInfo.games[url].marketJS) {
      iframe.contentWindow.levelToPlay = level;
      iframe.contentWindow.username = auth.currentUser.displayName;
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      docGameRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const gameData = docSnap.data();

          if (
            gameData.players_count === 1 &&
            gameData.differtPlayerFinished === true
          ) {
            //primo ha messo score
            setGameFinished(true);
            setScoreSubmitted(true);
            console.log("PRIMO HA MESSO SCORE");
            if (window.TelegramWebviewProxy && fromInlineMode) {
              const eventData = {
                query: `#c_${gameCode}`,
              };
              window.TelegramWebviewProxy.postEvent(
                "web_app_switch_inline_query",
                JSON.stringify(eventData)
              );
            }
            return;
          }
        } else {
          setScoreSubmitted(true);
          console.log("game finished");
          setGameFinished(true);
        }
      },
      (error) => {
        console.error("Error fetching document: ", error);
      }
    );

    return () => unsubscribe();
  }, []);

  async function postFinished() {
    setGameFinished(true);
    const funct = httpsCallable(functions, "actionRoomGame");

    const param = {
      game_id: gameID,
      score: score,
      action: "post_differt_score",
    };

    funct(param)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function postGiveUp() {
    setGameFinished(true);
    const funct = httpsCallable(functions, "actionRoomGame");

    const param = {
      game_id: gameID,
      action: "give_up",
    };

    funct(param)
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    auth.currentUser.getIdToken().then((tok) => {
      userTokenId = tok;
    });

    const riceviMessaggio = (event) => {
      if (event.data.type === "score_" + url) {
        const numScore = event.data.numScore;
        score = numScore;
      }
      if (event.data.type === "finished_" + url) {
        postFinished();
      }
    };

    const handleBeforeUnload = async () => {
      const url = `https://us-central1-html5-gaming-bot.cloudfunctions.net/onDisconnectUser?tokenAuth=${encodeURIComponent(
        userTokenId
      )}&game_id=${encodeURIComponent(gameID)}&action=differt_game`;

      const success = navigator.sendBeacon(url);

      if (!success) {
        console.warn("Beacon request failed");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    window.addEventListener("message", riceviMessaggio);

    return () => {
      window.removeEventListener("message", riceviMessaggio);

      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  function back() {
    nav("/");
  }

  if (gameFinished) {
    return (
      <>
        <div style={{ background: ColorPalette.bgGradient, height: "100vh" }}>
          <RColumn>
            <Space height="90px" />
            <img src={`gameIcons/${url}.png`} width={"90px"} />
            <RTitile>Game finished!</RTitile>
            <Space />
            {!scoreSublitted && (
              <>
                <SpinningCircles />
                <RText>Submitting score...</RText>
              </>
            )}
            {scoreSublitted && (
              <>
                {firstToPlay && (
                  <>
                    <RText align={"center"}>
                      Score submitted, wait for someone to take the challange!
                    </RText>
                    <Space />
                    {window.TelegramWebviewProxy && !fromInlineMode && (
                      <SecondaryBtn
                        text={"Send challenge to friends"}
                        onClick={() => {
                          if (!window.TelegramWebviewProxy) {
                            return;
                          }
                          const eventData = {
                            query: `#c_${gameCode}`,
                            chat_types: fromInlineMode
                              ? []
                              : ["users", "groups", "channels"],
                          };
                          window.TelegramWebviewProxy.postEvent(
                            "web_app_switch_inline_query",
                            JSON.stringify(eventData)
                          );
                        }}
                      />
                    )}
                  </>
                )}
                {!firstToPlay && (
                  <>
                    <RText align={"center"}>
                      Score submitted, calculating winner...
                    </RText>
                    <RText align={"center"} size="15px" color="grey">
                      You will receive a message
                    </RText>
                  </>
                )}
                <Space height="60px" />
                {!fromInlineMode && (
                  <PrimaryBtn text={"Close"} onClick={back} />
                )}
                {fromInlineMode && !firstToPlay && (
                  <PrimaryBtn
                    text={"Close"}
                    onClick={() => {
                      if (window.TelegramWebviewProxy)
                        window.TelegramWebviewProxy.postEvent("web_app_close");
                    }}
                  />
                )}
              </>
            )}
          </RColumn>
        </div>
      </>
    );
  }
  return (
    <>
      <div style={{ position: "fixed", top: "14px", left: "12px" }}>
        <SecondaryBtn text={"Give up"} onClick={postGiveUp} />
      </div>
      <iframe
        id="gameFrame"
        width="100%"
        height={h - 5}
        src={linkGame}
        style={{ border: "none" }}
      ></iframe>
    </>
  );
}
