import { useEffect, useState } from "react";
import {
  InputField,
  LoadingScreen,
  Padding,
  PrimaryBtn,
  RColumn,
  RGrid,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { ColorPalette, GroupsDoc } from "../global/const";
import copyIcon from "../assets/copy.png";
import { copyString, playerPlaceholderImg } from "../global/util";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, db, functions } from "../firebase";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../compnents/errorMessagesComp";
import { httpsCallable } from "firebase/functions";
import { format } from "date-fns";
import { greenColor, redColor } from "../profile/profilePage";
import { ContainerBorder, PopUpModal, TabButton } from "../compnents/compes";

export default function FriendsPage({ userCode, isMobile }) {
  const [isAddingFriend, setIsAddingFriend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invitingCode, setInvitingCode] = useState("");

  const [friendToCancel, setFriendToCancel] = useState(null);
  const [myFriends, setMyFrineds] = useState([]);

  const inviteLinkPre = "https://t.me/TheGameHubBot?start=friend=" + userCode;

  const [typeView, setTypeView] = useState(0);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get("tab");
    if (tab === "received") {
      setTypeView(2);
    }
    getFriends();
  }, []);

  async function getFriends() {
    const friendsRef = collection(db, "users", auth.currentUser.uid, "friends");
    const q = query(friendsRef, orderBy("date", "desc"), limit(30));

    const friendsDocs = await getDocs(q);
    const friendsss = [];
    friendsDocs.docs.forEach((element) => {
      friendsss.push({ ...element.data(), code: element.id });
    });
    setMyFrineds(friendsss);
  }

  async function inviteFriendWithCode(code) {
    if (code.length !== 6) {
      showErrorNotification("Code must be 6 charater");
      return;
    }
    setIsLoading(true);
    const funct = httpsCallable(functions, "addFriend");
    try {
      const res = await funct({ code: code });
      if (res.data.success !== true) {
        showErrorNotification(res.data.msg);
        setIsLoading(false);
        return;
      }
      showSuccessNotification("Friend invited");
    } catch (error) {
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
    setIsAddingFriend(false);
    getFriends();
  }
  async function approveFriend(code) {
    //console.log(code);
    setIsLoading(true);
    const funct = httpsCallable(functions, "approveFriend");
    try {
      const res = await funct({ code: code });
      if (res.data.success !== true) {
        showErrorNotification(res.data.msg);
        setIsLoading(false);
        return;
      }
      showSuccessNotification("Friend accepted");
    } catch (error) {
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
    setIsAddingFriend(false);
    getFriends();
  }
  async function delateFriend(code) {
    //console.log(code);
    setIsLoading(true);
    setFriendToCancel(null);

    const funct = httpsCallable(functions, "delateFriend");
    try {
      const res = await funct({ code: code });
      if (res.data.success !== true) {
        showErrorNotification(res.data.msg);
        setIsLoading(false);
        return;
      }
      showSuccessNotification("Friend delated");
    } catch (error) {
      showErrorNotification("There was an error");
    }
    setIsLoading(false);
    setIsAddingFriend(false);
    getFriends();
    setFriendToCancel(null);
  }

  function FriendsWid() {
    const invitedNum = myFriends.filter(
      (f) => f.isSended === true && f.state === "waiting"
    ).length;

    const receivedNum = myFriends.filter(
      (f) => f.isSended === false && f.state === "waiting"
    ).length;

    return (
      <>
        <RGrid horizontalAlign="center" width={"100%"}>
          <TabButton onClick={() => setTypeView(0)} selected={typeView === 0}>
            Friends
          </TabButton>
          <SpaceHorizontal />
          <TabButton onClick={() => setTypeView(3)} selected={typeView === 3}>
            Groups
          </TabButton>
          {invitedNum > 0 && (
            <TabButton onClick={() => setTypeView(1)} selected={typeView === 1}>
              Invited ({invitedNum})
            </TabButton>
          )}
          {receivedNum > 0 && (
            <TabButton onClick={() => setTypeView(2)} selected={typeView === 2}>
              Received ({receivedNum})
            </TabButton>
          )}
        </RGrid>
        <Space />

        {typeView !== 3 && (
          <>
            {myFriends
              .filter((f) => {
                if (typeView === 0) return f.state === "confirmed";
                if (typeView === 1)
                  return f.state === "waiting" && f.isSended === true;
                if (typeView === 2)
                  return f.state === "waiting" && f.isSended === false;
              })
              .map((friend, ind) => (
                <SingleFriend data={friend} key={ind} />
              ))}
          </>
        )}
        {typeView === 3 && (
          <>
            <RText align={"center"} size="14px" color="grey">
              Add groups with the /addgroup command in the group you want to
              add.
            </RText>
            {GroupsDoc.groups.map((g) => (
              <ContainerBorder key={g.id}>
                <RRow>
                  <img
                    src={g.photo}
                    width={"42px"}
                    style={{ borderRadius: "100%" }}
                  />
                  <SpaceHorizontal />
                  <RText>{g.name}</RText>
                </RRow>
              </ContainerBorder>
            ))}
          </>
        )}
      </>
    );
  }

  function SingleFriend({ data }) {
    return (
      <>
        <ContainerBorder
          heightMobile=""
          heightPc=""
          margin="6px 0px"
          padding="12px 20px"
        >
          <RRow horizontalAlign="space-between" verticalAlignment="center">
            <RRow>
              <img
                style={{ borderRadius: "100%" }}
                src={data.avatarUrl ?? playerPlaceholderImg(data.uid)}
                width={"38px"}
              />
              <SpaceHorizontal />
              <RColumn horizontalAlign="start">
                <RText color="grey" size="15px">
                  {format(data.date, "iii d MMM")}
                </RText>
                <Space height="4px" />
                <RText weight="bold" size="18px">
                  {data.username}
                </RText>
              </RColumn>
            </RRow>
            <RRow>
              {data.state === "waiting" && data.isSended === true && (
                <RText size="14px" color="orange">
                  Waiting approval
                </RText>
              )}
              {data.state === "waiting" && data.isSended === false && (
                <PrimaryBtn
                  text={"Approve"}
                  onClick={() => approveFriend(data.code)}
                />
              )}
              <SpaceHorizontal />
              <div
                onClick={() => setFriendToCancel(data.code)}
                style={{ cursor: "pointer" }}
              >
                <RText weight="bold" color={redColor}>
                  X
                </RText>
              </div>
            </RRow>
          </RRow>
        </ContainerBorder>
      </>
    );
  }

  function DelateFriendModal() {
    return (
      <PopUpModal onClose={() => setFriendToCancel(null)}>
        <RTitile align={"center"}>Remove friend?</RTitile>
        <Space height="42px" />
        <RRow width={"100%"} horizontalAlign="space-evenly">
          <div
            onClick={() => setFriendToCancel(null)}
            style={{ cursor: "pointer" }}
          >
            <RText>Cancel</RText>
          </div>
          <SecondaryBtn
            text={"Remove"}
            onClick={() => delateFriend(friendToCancel)}
          />
        </RRow>
      </PopUpModal>
    );
  }
  return (
    <>
      {isAddingFriend && (
        <PopUpModal onClose={() => setIsAddingFriend(false)}>
          <RColumn width={"100%"} horizontalAlign="space-between">
            <InputField
              width="100%"
              placeHolder={"Friend code"}
              maxChar={6}
              value={invitingCode}
              setValue={(v) => setInvitingCode(v.target.value.toUpperCase())}
            />
            <Space />
            <PrimaryBtn
              onClick={() => inviteFriendWithCode(invitingCode)}
              text={"Send request"}
            />
          </RColumn>
        </PopUpModal>
      )}
      {friendToCancel && <DelateFriendModal />}

      {isLoading && <LoadingScreen />}
      {/*  <InviteModal/> */}
      <Space />
      <RRow horizontalAlign="space-between" width={"100%"}>
        <div onClick={() => copyString(userCode, "Code copied")}>
          <RRow>
            <img src={copyIcon} width={"14px"} />
            <SpaceHorizontal />
            <RText>Your code: {userCode}</RText>
          </RRow>
        </div>
        <SecondaryBtn
          text={"Add friends"}
          onClick={() => setIsAddingFriend(true)}
        />
      </RRow>
      <Space />
      <FriendsWid />
      <Space />
    </>
  );
}
