import { useEffect, useState } from "react";
import {
  RColumn,
  RRow,
  RText,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { ContainerBorder } from "../compnents/compes";
import { copyString, formatNumber } from "../global/util";
import { BaseInfo } from "../global/const";
import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "../firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import LoadingIcons from "react-loading-icons";
import { RiFileCopy2Line, RiFileCopyLine } from "@remixicon/react";
import { format } from "date-fns";
import { eth_address } from "../aStartingPage";

export default function WalletDeposit({ Head }) {
  const [tokenSelected, setTokenSelected] = useState(null);
  const [coinSelected, setCoinSelected] = useState({ coin: null, chain: null });
  const [state, setState] = useState(0);

  const [depositAddress, setDepositAddress] = useState({
    state: "loading",
    value: null,
    min: 0,
    valid_until: "",
  });

  async function getDepositAddress() {
    if (depositAddress.state === "end") return;
    if (eth_address && coinSelected.coin?.toLowerCase() === "ghub") {
      setDepositAddress({
        state: "end",
        value: eth_address,
        min: 1000,
        valid_until: null,
      });

      return;
    }

    /*  const depsRep = collection(
      db,
      `users/${auth.currentUser.uid}/nowPayment-deposit`
    );
    const q = query(
      depsRep,
      //  orderBy("valid_until", "desc"),
      orderBy("expiration_estimate_date", "desc"),
      where("pay_currency", "==", coinSelected.coin.toLocaleLowerCase()),
      limit(1)
    );
    const lastDeposit = await getDocs(q);
    if (!lastDeposit.empty) {
      const dat = lastDeposit.docs[0].data();
      const validUntil = new Date(dat.valid_until);
      const now = new Date();
      console.log(dat);
      if (now < validUntil) {
        console.log("already deposit");
        setDepositAddress({
          state: "end",
          value: dat.pay_address,
          min: dat.minimum,
          valid_until: dat.valid_until,
        });
        return;
      }
    } */
    const funct = httpsCallable(functions, "getDepositAddressNowPayment");
    try {
      const res = await funct({ currency: coinSelected.coin });
      console.log(res);
      setDepositAddress({
        state: "end",
        value: res.data.address,
        min: res.data.minimum,
        valid_until: res.data.valid_until,
      });
    } catch (e) {
      console.log(e);
      setDepositAddress({ state: "error", value: "Error retriving address" });
    }
  }

  useEffect(() => {
    if (state === 2) {
      getDepositAddress();
    }
  }, [state]);

  return (
    <div style={{ padding: "14px" }}>
      <Head>Deposit</Head>
      <Space />

      <Space height="8px" />
      {state === 0 && (
        <>
          <RText size="18px">Choose the token</RText>
          {BaseInfo.baseInfoDoc.coinSupported.map((v, i) => (
            <ContainerBorder
              key={v.symbol}
              padding="10px"
              onClick={() => {
                setTokenSelected(v);
                setState(1);
              }}
              cursorPointer
            >
              <RRow>
                <img src={v.img} width={"22px"} />
                <SpaceHorizontal />
                <RColumn horizontalAlign="start">
                  <RText size="18px">{v.symbol}</RText>
                  <Space height="4px" />
                  <RText size="16px" color={"grey"}>
                    {v.name}
                  </RText>
                </RColumn>
              </RRow>
            </ContainerBorder>
          ))}
        </>
      )}
      {state === 1 && (
        <>
          <RRow horizontalAlign="start">
            <img src={tokenSelected.img} width={"22px"} />
            <SpaceHorizontal />
            <RColumn horizontalAlign="start">
              <RText size="18px">{tokenSelected.symbol}</RText>
              <Space height="4px" />
              <RText size="16px" color={"grey"}>
                {tokenSelected.name}
              </RText>
            </RColumn>
          </RRow>
          <Space />
          <RText>Choose Blockchain</RText>
          <Space height="8px" />
          {tokenSelected.chains.map((v, i) => (
            <ContainerBorder
              key={v.name}
              cursorPointer
              padding="14px 12px"
              onClick={() => {
                setCoinSelected({ coin: v.coin, chain: v.name });
                setState(2);
              }}
            >
              <RText>{v.name}</RText>
            </ContainerBorder>
          ))}
        </>
      )}
      {state === 2 && (
        <>
          <RColumn>
            <img src={tokenSelected.img} width={"42px"} />
            <Space height="12px" />
            <RText>Deposit {tokenSelected.symbol}</RText>
          </RColumn>

          <Space />
          <RText size="16px" color={"grey"}>
            Network
          </RText>
          <Space height="4px" />
          <RText>{coinSelected.chain}</RText>
          <Space />
          <RText size="16px" color={"grey"}>
            Minimum deposit
          </RText>
          <Space height="4px" />
          <RText>
            {formatNumber(depositAddress.min, 5)} {tokenSelected.symbol}
          </RText>
          {depositAddress.valid_until && (
            <>
              <Space />
              <RText size="16px" color={"grey"}>
                Expiration date
              </RText>
              <Space height="4px" />
              <RText>
                {depositAddress.valid_until.replace("T", " ").slice(0, 16)}
              </RText>

              <Space height="4px" />
              <RText size="14px" color={"orange"}>
                Please do not make deposits after the expiration date. To
                deposit, always follow the process using the deposit button.
                Funds sent to the wallet after the expiration date will not be
                credited.
              </RText>
            </>
          )}
          <Space />
          <RText size="16px" color={"grey"}>
            Deposit Address
          </RText>
          <Space height="4px" />
          <div
            style={{ cursor: "pointer" }}
            onClick={() => copyString(depositAddress.value, "Address copied")}
          >
            <RRow>
              <RiFileCopyLine size={18} color="white" />
              <SpaceHorizontal width="4px" />

              {depositAddress.state === "end" && (
                <RText>{depositAddress.value}</RText>
              )}
            </RRow>
          </div>

          {depositAddress.state === "loading" && (
            <LoadingIcons.ThreeDots width={"24px"} />
          )}
          {depositAddress.state === "error" && (
            <RText color="red">{depositAddress.value}</RText>
          )}
        </>
      )}
      <Space />
    </div>
  );
}
