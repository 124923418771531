import {
  InputField,
  PrimaryBtn,
  RColumn,
  RGrid,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import {
  BaseInfo,
  ColorPalette,
  StatusInfo,
  isTest,
  tokeABI,
} from "../global/const";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getDateFinishP2eComp,
  isP2eCompActive,
  isP2EStarting,
} from "../global/util";
import { levelImgs } from "./tabController";
import {
  BadgeWhiteBg,
  BuyBtns,
  ContainerBorder,
  CountdownTimer,
  NewIcon,
} from "../compnents/compes";
import bannerImg from "../assets/TG platform banner.jpg";
import { isDesktop } from "react-device-detect";
import useWindowWidth from "../util";
import { useGameType } from "../theme";
import LoadingIcons from "react-loading-icons";
import { showErrorNotification } from "../compnents/errorMessagesComp";

let firstTimeShuffle = false;

export function HomePage({ tgUsername, isMobile }) {
  const [gameTypeSelected, setGameTypeSelected] = useState(0);
  const gameTypeName = ["ALL", "PVP", "P2E", "F2P" /* , "NEW" */];
  const nav = useNavigate();

  const [countDownComp, setCountDownComp] = useState("");
  const { userBalances } = useGameType();

  const [searchingGames, setSearchingGames] = useState("");
  const [isDektopBannerOpen, setIsDesktopBannerOpen] = useState(true);

  let games = [
    { name: BaseInfo.gamesName["head-soccer"], type: 1, url: "head-soccer" },
    { name: BaseInfo.gamesName.crazy_cannons, type: 1, url: "crazy_cannons" },
    { name: BaseInfo.gamesName.box_tower, type: 1, url: "box_tower" },
    { name: BaseInfo.gamesName.skybattle, type: 1, url: "skybattle" },
    { name: BaseInfo.gamesName.battleship, type: 1, url: "battleship" },
    { name: BaseInfo.gamesName.ballpol, type: 1, url: "ballpol" },
    { name: BaseInfo.gamesName.chess, type: 1, url: "chess" },
    { name: BaseInfo.gamesName.airhockey, type: 1, url: "airhockey" },
    { name: BaseInfo.gamesName.footpong, type: 1, url: "footpong" },
    { name: BaseInfo.gamesName.basketball, type: 1, url: "basketball" },
    { name: BaseInfo.gamesName.dartspro, type: 1, url: "dartspro" },
    { name: BaseInfo.gamesName.uno, type: 1, url: "uno" },
    { name: BaseInfo.gamesName.golf, type: 1, url: "golf" },
    { name: BaseInfo.gamesName.goal, type: 1, url: "goal" },
    { name: BaseInfo.gamesName.flappyChicken, type: 1, url: "flappyChicken" },
    {
      name: BaseInfo.gamesName.race,
      type: 1,
      url: "race",
      link: "racing/index.html",
    },
    //  {name: BaseInfo.gamesName.roulette, type: 3,  url: "roulette"},
    {
      name: BaseInfo.gamesName.pinball,
      type: 2,
      url: "pinball",
      link: "pin_ball/index.html",
    },
    {
      name: BaseInfo.gamesName.templeDash,
      type: 2,
      url: "templeDash",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-temple-dash-mania/1711428315288/index.html",
    },
    {
      name: BaseInfo.gamesName.skyHunter,
      type: 2,
      url: "skyHunter",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-atlantic-sky-hunter/1711421649983/index.html",
    },
    {
      name: BaseInfo.gamesName.flappyChicken,
      type: 2,
      url: "flappyChicken",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-flappy-chicken/1711432698587/index.html",
    },
    {
      name: BaseInfo.gamesName.brawlhero,
      type: 2,
      url: "brawlhero",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-brawl-hero/1729587988145/index.html",
    },
    {
      name: BaseInfo.gamesName.sporehunter,
      type: 2,
      url: "sporehunter",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-spore-hunter-hd/1728359452088/index.html",
    },
    {
      name: BaseInfo.gamesName.coolarcher,
      type: 2,
      url: "coolarcher",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-cool-archer-hd/1730092037078/index.html",
    },
    {
      name: BaseInfo.gamesName.ballJump,
      type: 2,
      url: "ballJump",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-ball-jump/1711421159837/index.html",
    },
    {
      name: BaseInfo.gamesName.lateToWork,
      type: 2,
      url: "lateToWork",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-late-to-work/1713158770429/index.html",
    },
    {
      name: BaseInfo.gamesName.jetSkiRush,
      type: 2,
      url: "jetSkiRush",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-jet-ski-rush/1714630429472/index.html",
    },
    {
      name: BaseInfo.gamesName.riskyTrainCrossing,
      type: 2,
      url: "riskyTrainCrossing",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-risky-train-crossing/1711435639435/index.html",
    },
    {
      name: BaseInfo.gamesName.kawaiiJump,
      type: 2,
      url: "kawaiiJump",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-kawaii-jump/1711448571719/index.html",
    },
    {
      name: BaseInfo.gamesName.zombie_highway,
      type: 2,
      url: "zombie_highway",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-zombie-highway-rampage/1726815815048/index.html",
    },
    {
      name: BaseInfo.gamesName.zombie_run,
      type: 2,
      url: "zombie_run",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-omg-zombie-run/1729576578950/index.html",
    },
    {
      name: BaseInfo.gamesName.evade_missile,
      type: 2,
      url: "evade_missile",
      link: "https://thegamehub.marketjs-cloud2.com/en/thegamehub-evade-the-missile/1727082592376/index.html",
    },
  ];
  let gamesActive = games;

  if (isTest === false) {
    gamesActive = gamesActive.filter(
      (g) =>
        BaseInfo.gamesNow.includes(g.url) ||
        BaseInfo.p2eGames.games_active.some(
          (pg) => pg.url === g.url && g.type === 2
        ) /* ||
        BaseInfo.p2eGames.games_old.some((pg) => pg.url === g.url) */
    );
    if (StatusInfo.data.p2e === false) {
      gamesActive = gamesActive.filter((g) => g.type !== 2);
    }
    if (StatusInfo.data.pvp === false) {
      gamesActive = gamesActive.filter((g) => g.type !== 1);
    }

    if (StatusInfo.data.pve === false) {
      gamesActive = gamesActive.filter((g) => g.type !== 3);
    }
    if (StatusInfo.data.f2p === false) {
      gamesActive = gamesActive.filter((g) => g.type !== 4);
    }
  }

  let gamesInactive = games.filter(
    (g) => !gamesActive.some((activeGame) => activeGame.url === g.url)
  );
  if (!firstTimeShuffle) {
    firstTimeShuffle = true;

    for (let i = gamesActive.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [gamesActive[i], gamesActive[j]] = [gamesActive[j], gamesActive[i]];
    }

    for (let i = gamesInactive.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [gamesInactive[i], gamesInactive[j]] = [
        gamesInactive[j],
        gamesInactive[i],
      ];
    }
  }
  games = [...gamesActive, ...gamesInactive];
  const queryParams = new URLSearchParams(window.location.search);
  const showOnlyGames = queryParams.get("inline") === "btn";

  useEffect(() => {
    if (queryParams.get("inline") === "btn") {
      setGameTypeSelected(1);
    }
    if (queryParams.get("gameUrl")) {
      goToLink(queryParams.get("gameUrl"), 1, null);
    }
  }, []);
  const goToLink = (url, type, link) => {
    if (type === 1) nav("/lobby", { state: { gameUrl: url } });
    else if (type === 3) nav("/" + url);
    else if (type === 4) nav("/free", { state: { link: link, url: url } });
    else if (type === 2) {
      if (!isTest) {
        if (!isP2EStarting()) nav("/p2e", { state: { link: link, url: url } });
      } else {
        nav("/p2e", { state: { link: link, url: url } });
      }
    }
  };

  function SingleGame({
    title,
    url,
    type,
    link,
    isNew = false,
    bgGradient = false,
  }) {
    const isActive = gamesActive.some((g) => g.url === url);
    return (
      <ContainerBorder
        cursorPointer={isActive}
        onClick={isActive ? () => goToLink(url, type, link) : null}
        margin="0px 0px"
        width={isMobile ? "47%" : "200px"}
        heightMobile=""
        heightPc=""
        padding="0 20px"
        isNew={isNew}
        isMobile={isMobile}
        gradientBg={bgGradient}
      >
        <div className={isActive ? "" : "container-blur"}>
          <div className={isActive ? "" : "overlay-blur"}></div>
          {!isActive && (
            <div className="centered-text">
              <RText align={"center"}>Coming soon</RText>
            </div>
          )}
          <div className={isActive ? "" : "content-blur"}>
            <RColumn verticalAlignment="start" height="100%" width={"100%"}>
              <img src={"gameIcons/" + url + ".png"} height={"130px"} />
              {/*  {games.filter((g) => g.url === url).length > 1 && (
                <RText>{gameTypeName[type]}</RText>
              )} */}
            </RColumn>
          </div>
        </div>
      </ContainerBorder>
    );
  }

  const SingleTypeGameBtn = ({ type }) => {
    return (
      <div
        onClick={() => setGameTypeSelected(type)}
        style={{
          cursor: "pointer",
          backgroundColor:
            type === gameTypeSelected ? ColorPalette.accentColor2 : "",
          padding: "4px 8px",
          borderRadius: "8px",
          width: "10%",
        }}
      >
        <RText size="15px" align={"center"}>
          {gameTypeName[type]}
        </RText>
      </div>
    );
  };

  function getRemainingTimeUntilTarget(targetDateString) {
    const now = new Date(); // Data corrente
    const target = new Date(targetDateString); // Data di destinazione
    const timeDiff = target - now;

    if (timeDiff > 0) {
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      if (hours > 0)
        return `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
      else if (minutes > 0) return `${minutes} Minutes ${seconds} Seconds`;
      else return `${seconds} Seconds`;
    } else {
      return false;
    }
  }
  return (
    <div
      style={{
        background: ColorPalette.bgGradient,
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Space height="20px" />
      <div style={{ padding: "0px 20px" }}>
        {!isMobile && (
          <>
            <RRow verticalAlignment="center" horizontalAlign="space-between">
              <RRow>
                <BadgeWhiteBg userBalances={userBalances} />
                <SpaceHorizontal />
                <RTitile>Hello, {tgUsername}</RTitile>
                <SpaceHorizontal width="32px" />
                <div
                  style={{
                    backgroundColor: ColorPalette.accentColor2,
                    borderRadius: "10px",
                    padding: "5px 12px",
                  }}
                >
                  {userBalances.isLoading === true ? (
                    <LoadingIcons.ThreeDots height={"8px"} width={"22px"} />
                  ) : (
                    <RText size="15px">
                      <strong>{userBalances.total_usd.toFixed(2)}</strong> USD
                    </RText>
                  )}
                </div>
              </RRow>
              <RRow>
                <InputField
                  padding="6px 12px"
                  bgColor={ColorPalette.accentColor2Bg}
                  placeHolder={"Search..."}
                  setValue={(e) => setSearchingGames(e.target.value)}
                  value={searchingGames}
                  width="200px"
                />
                <SpaceHorizontal />
              </RRow>
            </RRow>
            <Space />
          </>
        )}
        {window.innerWidth < 800 && isDesktop && isDektopBannerOpen && (
          <div style={{ position: "relative" }}>
            <ContainerBorder>
              <div
                onClick={() => setIsDesktopBannerOpen(false)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "8px",
                  top: "6px",
                }}
              >
                <RText>X</RText>
              </div>
              <RText>
                You can play on the browser in full screen with the command{" "}
                <span style={{ color: ColorPalette.accentColor }}>
                  /desktop
                </span>{" "}
                in the telegram bot
              </RText>
            </ContainerBorder>
          </div>
        )}

        {!showOnlyGames && (
          <>
            <img
              style={{
                borderRadius: isMobile ? "12px" : "32px",
                boxShadow: "0px 0px 32px rgba(11, 153, 224, 0.6)",
              }}
              src={bannerImg}
              width={"100%"}
            />
            <BuyBtns />
          </>
        )}
        <Space height={isMobile ? "4px" : undefined} />
        {isMobile && (
          <InputField
            padding="6px 12px"
            bgColor={ColorPalette.accentColor2Bg}
            placeHolder={"Search..."}
            setValue={(e) => setSearchingGames(e.target.value)}
            value={searchingGames}
            width="100%"
          />
        )}
        <Space height={isMobile ? "4px" : undefined} />
        {!searchingGames &&
          gameTypeSelected === 0 &&
          (isP2eCompActive() || isP2EStarting()) &&
          games.filter((game) =>
            BaseInfo.p2eGames.games_active.some((g) => g.url === game.url)
          ).length > 0 && (
            <RColumn width={"100%"}>
              <div style={{ maxWidth: "500px", width: "100%" }}>
                <RTitile align={"center"} color={ColorPalette.accentColor}>
                  P2E competition
                </RTitile>
                <CountdownTimer />
                <RGrid horizontalAlign="center">
                  {games
                    .filter((game) =>
                      BaseInfo.p2eGames.games_active.some(
                        (g) => g.url === game.url && game.type === 2
                      )
                    )
                    .map((g, ind) => (
                      <SingleGame
                        bgGradient
                        key={ind}
                        title={g.name}
                        link={g.link}
                        url={g.url}
                        type={2}
                      />
                    ))}
                </RGrid>
              </div>
              <Space />
            </RColumn>
          )}
        <div style={{ width: "100%" }}>
          {!showOnlyGames && (
            <RRow horizontalAlign="center">
              {/*  <div style={{width: isMobile ? "": "100%"}}>
            {!isMobile && <RTitile align="center" weight="bold" size="26px">GAMES</RTitile>}
            </div> */}
              <div
                style={{
                  backgroundColor: ColorPalette.accentColor2Bg,
                  maxWidth: "500px",
                  width: "100%",
                  minWidth: "320px",
                  padding: "6px 6px",
                  borderRadius: "12px",
                }}
              >
                <RRow horizontalAlign={"space-between"} width={"100%"}>
                  {gameTypeName.map((tit, ind) => (
                    <SingleTypeGameBtn key={tit} type={ind} />
                  ))}
                </RRow>
              </div>
            </RRow>
          )}
          {showOnlyGames && (
            <>
              <Space />
              <RText align={"center"} color="grey">
                Choose game to challenge friends
              </RText>
            </>
          )}
          <Space />
          <RGrid
            width={"100%"}
            gap={isMobile ? "12px" : "22px"}
            horizontalAlign={
              games.length < 3 && !isMobile ? "center" : "space-between"
            }
          >
            {games
              .filter(
                (g) =>
                  (gameTypeSelected === 0
                    ? true
                    : gameTypeSelected === 5
                    ? BaseInfo.baseInfoDoc.gamesNew.includes(g.url)
                    : g.type === gameTypeSelected) &&
                  (searchingGames
                    ? (g.name ?? "")
                        .toLowerCase()
                        .includes(searchingGames?.toLowerCase())
                    : true)
              )
              .map((game, index) => (
                <SingleGame
                  link={game.link}
                  title={game.name}
                  url={game.url}
                  type={game.type}
                  key={"game" + index}
                  isNew={BaseInfo.baseInfoDoc.gamesNew.includes(game.url)}
                />
              ))}
          </RGrid>
        </div>
        {games.filter((g) =>
          gameTypeSelected === 0
            ? g
            : gameTypeSelected === 4
            ? BaseInfo.baseInfoDoc.gamesNew.includes(g.url)
            : g.type === gameTypeSelected
        ).length === 0 && (
          <RColumn>
            <Space height="46px" />
            <RText>Coming soon</RText>
          </RColumn>
        )}
        <Space height="100px" />
      </div>
    </div>
  );
}
