import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { ContainerBorder } from "../compnents/compes";
import {
  RColumn,
  RRow,
  RText,
  RTitile,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { isMobile } from "react-device-detect";
import { greenColor } from "../profile/profilePage";
import { Leaderboard2 } from "../p2eGames/lead2";
import { supabase } from "../supabase";

let cachedLeadP = null;

export default function PointsLeaderboard() {
  const [leadEntries, setLeadEntries] = useState([]);

  useEffect(() => {
    getLead();
  }, []);

  async function getLead() {
    if (cachedLeadP) {
      setLeadEntries(cachedLeadP);
      return;
    }
    /* const statsRef = collection(db, "user-points");
    const q = query(statsRef, orderBy("points", "desc"), limit(10)); 
     const docSnapshot = await getDocs(q);
    */

    const { data: querySupa, error } = await supabase.rpc("leaderboard_points");
    if (error) {
      console.log(error);
      return;
    }

    let newEntry = [];
    let i = 1;
    querySupa.forEach((entry) => {
      newEntry.push({
        rank: i,
        score: entry.points,
        username: entry.username,
        avatarUrl: entry.avatarurl,
      });
      i++;
    });
    cachedLeadP = newEntry;
    setLeadEntries(newEntry);
  }

  function SingleEntry({ entry }) {
    return (
      <ContainerBorder
        gradientBg={entry.uid === auth.currentUser.uid}
        heightMobile=""
        heightPc=""
        margin="8px 0px"
        padding="12px 20px"
      >
        <RRow
          width={"100%"}
          horizontalAlign="space-between"
          verticalAlignment="center"
        >
          <RRow verticalAlignment="center">
            <RTitile size={isMobile ? "20px" : undefined}>{entry.rank}</RTitile>
            <SpaceHorizontal />
            <RText weight="bold" size={isMobile ? "16px" : undefined}>
              {entry.username ??
                "GHUB Player" + entry.uid.slice(0, 3).slice(0, 20)}
            </RText>
          </RRow>
          <RColumn horizontalAlign="end">
            <RTitile size={isMobile ? "18px" : undefined}>
              {entry.points}
            </RTitile>
          </RColumn>
        </RRow>
      </ContainerBorder>
    );
  }

  return (
    <>
      {/*  {leadEntries.map((entry, ind) => (
        <SingleEntry entry={entry} key={ind} />
      ))} */}

      <Leaderboard2 players={leadEntries} gameName={"Points"} />
    </>
  );
}
