import { CountdownTimer, PopUpModal, TimerToMidUtc } from "../compnents/compes";
import {
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { ColorPalette } from "../global/const";
import pointsIcon from "../assets/reward.png";
import {
  RiCharacterRecognitionFill,
  RiCheckFill,
  RiCheckLine,
  RiLockFill,
  RiLockLine,
  RiTimer2Line,
} from "@remixicon/react";

export default function StrikeModal({ isOpen, onClose, strikeCount }) {
  if (!isOpen) return null;
  const rewards = [
    { day: 0, index: -1 },
    { day: 1, index: 0, type: "points", reward: 10, suffix: " pts" },
    { day: 2, index: 1, type: "points", reward: 20, suffix: " pts" },
    { day: 3, index: 2, type: "points", reward: 1, suffix: " P2E" },
    { day: 4, index: 3, type: "points", reward: 40, suffix: " pts" },
    { day: 5, index: 4, type: "points", reward: 50, suffix: " pts" },
    { day: 6, index: 5, type: "points", reward: 50, suffix: " pts" },
    { day: 7, index: 6, type: "points", reward: 75, suffix: " pts" },
  ];

  function SingleDayCircle({ rew }) {
    return (
      <div
        style={{
          margin: "2px",
          width: "27px",
          height: "27px",
          backgroundColor:
            rew.day === strikeCount
              ? ColorPalette.accentColor
              : ColorPalette.bgColor,
          borderRadius: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RText>{rew.day}</RText>
      </div>
    );
  }

  function SingleDayRew({ rew }) {
    return (
      <div style={{ margin: "12px 0px" }}>
        <RRow width={"100%"} horizontalAlign="center">
          <div style={{ width: "100px" }}>
            <RTitile align={"right"}>DAY {rew.day}</RTitile>
          </div>
          <SpaceHorizontal width="22px" />
          {rew.day <= strikeCount ? (
            <RiCheckFill size={24} color={"green"} />
          ) : (
            <RiLockLine size={24} color={"grey"} />
          )}
          <SpaceHorizontal width="22px" />

          <RRow width={"100px"}>
            <RTitile>{rew.reward}</RTitile>
            <SpaceHorizontal width="8px" />
            <RText color="gray">{rew.suffix}</RText>
          </RRow>
        </RRow>
      </div>
    );
  }
  return (
    <PopUpModal onClose={onClose} bgColor={ColorPalette.tabColor}>
      <RColumn>
        <RTitile>STRIKE</RTitile>
        <Space />
        <RRow horizontalAlign="space-between" width={"100%"}>
          <RText>Day</RText>
          <SpaceHorizontal />
          <RRow>
            {rewards.map((rew) => (
              <SingleDayCircle key={rew.index} rew={rew} />
            ))}
          </RRow>
        </RRow>
        <Space />
        <RText align={"center"} color="grey">
          Login daily to maintain your streak and earn rewards!
        </RText>
        <Space />
        {rewards.slice(1).map((rew) => (
          <SingleDayRew key={"r" + rew.index} rew={rew} />
        ))}
      </RColumn>
      <Space />
      <div style={{ position: "absolute", right: 12, bottom: 12 }}>
        <div
          style={{
            backgroundColor: "#3d3d3d",
            padding: "8px",
            borderRadius: "10px",
          }}
        >
          <RRow>
            <RiTimer2Line size={16} color="white" />
            <SpaceHorizontal width="6px" />
            <TimerToMidUtc />
          </RRow>
        </div>
      </div>
    </PopUpModal>
  );
}
